<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-row class="d-flex align-center">
            <v-col cols="12" sm="10">
              <v-row class="d-flex align-center">
                <v-col cols="12" sm="6" md="3">
                  <employee-picker v-model="table.criteria.id" clearable />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <branch-picker v-model="table.criteria.branches" multiple clearable />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <job-info-picker v-model="table.criteria.jobInfos" multiple clearable />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <title-picker v-model="table.criteria.titles" multiple clearable />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="2" class="d-flex justify-end align-start">
              <id-button @click="onRefreshData">
                <template #icon>
                  <v-icon left>mdi-refresh</v-icon>
                </template>
                {{ $t("buttons.refresh") }}
              </id-button>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="text-no-wrap">
                <employee-profile :employee="row.item" />
              </td>
              <td>{{ $helpers.get(row.item.employment.branch, "name") }}</td>
              <td>{{ $helpers.get(row.item.employment.jobInfo, "name") }}</td>
              <td>{{ $helpers.get(row.item.employment.title, "name") }}</td>
              <td>
                {{ row.item.workEmail || "-" }}
              </td>
              <td>{{ row.item.contact.workPhone || "-" }}</td>
              <td>{{ row.item.contact.extensionNumber || "-" }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-11 14:53:18
   * @modify date 2021-01-11 14:53:18
   * @desc Contact
   */
  import { EMPLOYEES } from "../query";

  export default {
    name: "Contact",
    components: {
      EmployeePicker: () => import("@/components/selectpickers/EmployeePicker"),
      BranchPicker: () => import("@/components/selectpickers/BranchPicker"),
      JobInfoPicker: () => import("@/components/selectpickers/JobInfoPicker"),
      TitlePicker: () => import("@/components/selectpickers/TitlePicker")
    },
    data: (vm) => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          id: null,
          branches: [],
          jobInfos: [],
          titles: []
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ["firstName"],
          sortDesc: [true]
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.contact.employee"),
            align: "start",
            sortable: true,
            value: "firstName",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employee.overview.branch"),
            align: "start",
            sortable: true,
            value: "employment.branch.name",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employee.overview.job_info"),
            align: "start",
            sortable: true,
            value: "employment.jobInfo.name",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employee.overview.title"),
            align: "start",
            sortable: true,
            value: "employment.title.name",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.contact.email"),
            align: "start",
            sortable: true,
            value: "workEmail",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.contact.phone"),
            align: "start",
            sortable: true,
            value: "contact.workPhone",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employee.contact.extension_number"),
            align: "start",
            sortable: true,
            value: "contact.extensionNumber",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: EMPLOYEES,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.table.options)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateEmployees.content || [];
              this.table.totalItems = data.filterWithPaginateEmployees.pageInfo.totalElements || 0;
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
